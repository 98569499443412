/* General styles for the app */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
}

h1, h2, p {
  margin: 0;
}

/* Button Styles */
button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

button:hover {
  transform: scale(1.05);
  background-color: #555; /* Slightly lighter background on hover */
}

button:active {
  transform: scale(0.95); /* Slightly shrink when pressed */
  background-color: #444; /* Darken background on click */
}

button:focus {
  outline: none; /* Remove focus outline */
}

/* Section Styles */
section {
  margin-bottom: 2rem;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  border-radius: 0.5rem;
  padding: 1rem;
}

/* Bottom Navigation Styles */
nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #111;
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  border-top: 1px solid #222;
}

nav div {
  text-align: center;
}

nav p {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}